import React from 'react'

import './logo.scss'

export const Logo = () => (
	<svg
		clipRule="evenodd"
		fillRule="evenodd"
		height="28"
		strokeLinejoin="round"
		strokeMiterlimit="2"
		viewBox="0 0 1024 1024"
		width="28"
		style={{ transform: 'scale(1.4142135624)' }}
	>
		<g className="rings">
			<path
				className="ring-0"
				d="M198.005,107.676c86.73,-67.474 195.705,-107.676 313.995,-107.676c23.671,0 46.969,1.61 69.791,4.726c-6.695,32.874 13.321,65.74 46.16,74.539c32.848,8.802 66.626,-9.666 77.255,-41.5c43.58,17.792 84.168,41.42 120.789,69.911c-22.151,25.125 -21.221,63.53 2.789,87.54c24.01,24.01 62.415,24.94 87.54,2.789c67.474,86.73 107.676,195.705 107.676,313.995c0,23.671 -1.61,46.969 -4.73,69.791c-32.87,-6.695 -65.736,13.321 -74.535,46.16c-8.802,32.848 9.666,66.626 41.5,77.255c-17.792,43.58 -41.42,84.168 -69.911,120.789c-25.125,-22.151 -63.53,-21.221 -87.54,2.789c-24.01,24.01 -24.94,62.415 -2.789,87.54c-86.73,67.474 -195.705,107.676 -313.995,107.676c-23.671,0 -46.969,-1.61 -69.791,-4.73c6.695,-32.87 -13.321,-65.736 -46.16,-74.535c-32.848,-8.802 -66.626,9.666 -77.255,41.5c-43.58,-17.792 -84.168,-41.42 -120.789,-69.911c22.151,-25.125 21.221,-63.53 -2.789,-87.54c-24.01,-24.01 -62.415,-24.94 -87.54,-2.789c-67.474,-86.73 -107.676,-195.705 -107.676,-313.995c0,-23.671 1.61,-46.969 4.726,-69.791c32.874,6.695 65.74,-13.321 74.539,-46.16c8.802,-32.848 -9.666,-66.626 -41.5,-77.255c17.792,-43.58 41.42,-84.168 69.911,-120.789c25.125,22.151 63.53,21.221 87.54,-2.789c24.01,-24.01 24.94,-62.415 2.789,-87.54Z"
				fill="#e83f16"
			/>
			<circle className="ring-1" cx="512" cy="512" r="384" fill="#ff641b" />
			<circle className="ring-2" cx="512" cy="512" r="288" fill="#ff851b" />
			<circle className="ring-3" cx="512" cy="512" r="192" fill="#ffc319" />
			<circle className="ring-4" cx="512" cy="512" r="96" fill="#ffdc00" />
			<circle className="ring-5" cx="512" cy="512" r="32" fill="#a8e816" />
		</g>
		<g className="dots-0-1">
			<path
				className="dot-0-1"
				d="M346.461,112.972c24.47,-10.152 52.578,1.473 62.73,25.942c10.152,24.47 -1.473,52.578 -25.942,62.73c-24.47,10.152 -52.578,-1.473 -62.73,-25.942c-10.152,-24.47 1.473,-52.578 25.942,-62.73Z"
				fill="#a8e816"
			/>
			<path
				className="dot-0-1"
				d="M169.129,249.21c16.116,-21.027 46.27,-25.014 67.297,-8.898c21.026,16.115 25.013,46.27 8.898,67.296c-16.116,21.027 -46.27,25.014 -67.297,8.898c-21.026,-16.115 -25.013,-46.27 -8.898,-67.296Z"
				fill="#e83f16"
			/>
			<path
				className="dot-0-1"
				d="M911.031,346.461c10.152,24.47 -1.473,52.578 -25.942,62.73c-24.47,10.152 -52.578,-1.473 -62.73,-25.942c-10.152,-24.47 1.473,-52.578 25.942,-62.73c24.47,-10.152 52.578,1.473 62.73,25.942Z"
				fill="#a8e816"
			/>
			<path
				className="dot-0-1"
				d="M774.794,169.129c21.027,16.116 25.014,46.27 8.898,67.297c-16.115,21.026 -46.27,25.013 -67.296,8.898c-21.027,-16.116 -25.014,-46.27 -8.898,-67.297c16.115,-21.026 46.27,-25.013 67.296,-8.898Z"
				fill="#e83f16"
			/>
			<path
				className="dot-0-1"
				d="M640.751,822.347c24.47,-10.151 52.578,1.473 62.73,25.943c10.152,24.469 -1.473,52.578 -25.942,62.729c-24.47,10.152 -52.578,-1.472 -62.73,-25.942c-10.152,-24.47 1.473,-52.578 25.942,-62.73Z"
				fill="#a8e816"
			/>
			<path
				className="dot-0-1"
				d="M778.679,716.39c16.116,-21.027 46.27,-25.014 67.297,-8.898c21.026,16.115 25.013,46.27 8.898,67.296c-16.116,21.027 -46.27,25.014 -67.297,8.898c-21.026,-16.115 -25.013,-46.27 -8.898,-67.296Z"
				fill="#e83f16"
			/>
			<path
				className="dot-0-1"
				d="M201.651,640.751c10.152,24.47 -1.473,52.578 -25.942,62.73c-24.47,10.152 -52.578,-1.473 -62.73,-25.942c-10.152,-24.47 1.473,-52.578 25.942,-62.73c24.47,-10.152 52.578,1.473 62.73,25.942Z"
				fill="#a8e816"
			/>
			<path
				className="dot-0-1"
				d="M307.606,778.679c21.027,16.116 25.014,46.27 8.898,67.297c-16.115,21.026 -46.27,25.013 -67.296,8.898c-21.027,-16.116 -25.014,-46.27 -8.898,-67.297c16.115,-21.026 46.27,-25.013 67.296,-8.898Z"
				fill="#e83f16"
			/>
		</g>
		<g className="dots-1-2">
			<path
				className="dot-1-2"
				d="M594.816,202.909c17.06,4.571 27.199,22.132 22.628,39.192c-4.571,17.059 -22.133,27.198 -39.192,22.627c-17.06,-4.571 -27.199,-22.132 -22.627,-39.192c4.571,-17.059 22.132,-27.198 39.191,-22.627Z"
				fill="#ffc319"
			/>
			<path
				className="dot-1-2"
				d="M429.173,202.901c17.059,-4.571 34.621,5.568 39.192,22.627c4.571,17.059 -5.568,34.621 -22.628,39.192c-17.059,4.571 -34.62,-5.568 -39.191,-22.628c-4.571,-17.059 5.568,-34.62 22.627,-39.191Z"
				fill="#ff851b"
			/>
			<path
				className="dot-1-2"
				d="M285.727,285.725c12.488,-12.488 32.766,-12.488 45.255,0c12.488,12.489 12.488,32.767 0,45.255c-12.489,12.489 -32.767,12.489 -45.255,0c-12.488,-12.488 -12.488,-32.766 0,-45.255Z"
				fill="#ff641b"
			/>
			<path
				className="dot-1-2"
				d="M821.091,594.816c-4.571,17.06 -22.132,27.199 -39.192,22.628c-17.059,-4.571 -27.198,-22.133 -22.627,-39.192c4.571,-17.06 22.132,-27.199 39.192,-22.627c17.059,4.571 27.198,22.132 22.627,39.191Z"
				fill="#e83f16"
			/>
			<path
				className="dot-1-2"
				d="M821.098,429.173c4.571,17.059 -5.568,34.621 -22.627,39.192c-17.059,4.571 -34.621,-5.568 -39.192,-22.628c-4.571,-17.059 5.568,-34.62 22.628,-39.191c17.059,-4.571 34.62,5.568 39.191,22.627Z"
				fill="#ff851b"
			/>
			<path
				className="dot-1-2"
				d="M738.275,285.727c12.488,12.488 12.488,32.766 0,45.255c-12.489,12.488 -32.767,12.488 -45.255,0c-12.489,-12.489 -12.489,-32.767 0,-45.255c12.488,-12.488 32.766,-12.488 45.255,0Z"
				fill="#ff641b"
			/>
			<path
				className="dot-1-2"
				d="M445.736,759.279c17.06,4.571 27.199,22.132 22.628,39.192c-4.571,17.059 -22.133,27.198 -39.192,22.627c-17.06,-4.571 -27.199,-22.132 -22.627,-39.192c4.571,-17.059 22.132,-27.198 39.191,-22.627Z"
				fill="#ffc319"
			/>
			<path
				className="dot-1-2"
				d="M578.253,759.274c17.059,-4.571 34.621,5.568 39.192,22.627c4.571,17.059 -5.568,34.621 -22.628,39.192c-17.059,4.571 -34.62,-5.568 -39.191,-22.628c-4.571,-17.059 5.568,-34.62 22.627,-39.191Z"
				fill="#ff851b"
			/>
			<path
				className="dot-1-2"
				d="M693.017,693.014c12.488,-12.488 32.766,-12.488 45.255,0c12.488,12.489 12.488,32.767 0,45.255c-12.489,12.489 -32.767,12.489 -45.255,0c-12.488,-12.488 -12.488,-32.766 0,-45.255Z"
				fill="#ff641b"
			/>
			<path
				className="dot-1-2"
				d="M264.721,445.736c-4.571,17.06 -22.132,27.199 -39.192,22.628c-17.059,-4.571 -27.198,-22.133 -22.627,-39.192c4.571,-17.06 22.132,-27.199 39.192,-22.627c17.059,4.571 27.198,22.132 22.627,39.191Z"
				fill="#e83f16"
			/>
			<path
				className="dot-1-2"
				d="M264.728,578.253c4.571,17.059 -5.568,34.621 -22.627,39.192c-17.059,4.571 -34.621,-5.568 -39.192,-22.628c-4.571,-17.059 5.568,-34.62 22.628,-39.191c17.059,-4.571 34.62,5.568 39.191,22.627Z"
				fill="#ff851b"
			/>
			<path
				className="dot-1-2"
				d="M330.981,693.017c12.488,12.488 12.488,32.766 0,45.255c-12.489,12.488 -32.767,12.488 -45.255,0c-12.489,-12.489 -12.489,-32.767 0,-45.255c12.488,-12.488 32.766,-12.488 45.255,0Z"
				fill="#ff641b"
			/>
		</g>
		<g className="dots-2-3">
			<path
				className="dot-2-3"
				d="M623.999,318.013c15.295,8.831 20.544,28.418 11.713,43.713c-8.831,15.295 -28.418,20.543 -43.713,11.712c-15.295,-8.83 -20.543,-28.417 -11.713,-43.712c8.831,-15.295 28.418,-20.544 43.713,-11.713Z"
				fill="#ff641b"
			/>
			<path
				className="dot-2-3"
				d="M736,512c0,17.661 -14.339,32 -32,32c-17.661,0 -32,-14.339 -32,-32c0,-17.661 14.339,-32 32,-32c17.661,0 32,14.339 32,32Z"
				fill="#a8e816"
			/>
			<path
				className="dot-2-3"
				d="M431.999,650.563c15.295,8.831 20.544,28.418 11.713,43.713c-8.831,15.295 -28.418,20.543 -43.713,11.712c-15.295,-8.83 -20.543,-28.417 -11.713,-43.712c8.831,-15.295 28.418,-20.544 43.713,-11.713Z"
				fill="#ff641b"
			/>
			<path
				className="dot-2-3"
				d="M352,512c0,17.661 -14.339,32 -32,32c-17.661,0 -32,-14.339 -32,-32c0,-17.661 14.339,-32 32,-32c17.661,0 32,14.339 32,32Z"
				fill="#a8e816"
			/>
		</g>
	</svg>
)
